@tailwind base;
@tailwind components;
@tailwind utilities;

html{
    scroll-behavior: smooth;
}


body{
    font-family: 'Josefin Sans', sans-serif;
    background: radial-gradient(#c5d0de, #f3f5f6);
    scroll-behavior: smooth !important;
}
.border-move{
    border-radius: 50px 200px 20px 110px;
    animation: bordersmove 20s infinite;
    filter: drop-shadow(0px 10px 6px rgba(0, 0, 0, 0.485));
}
@keyframes bordersmove {
    0%{border-radius: 270px 260px 260px 390px;}
    20%{border-radius: 290px 420px 360px 450px;}
    40%{border-radius: 250px 320px 500px 290px;}
    60%{border-radius: 350px 230px 300px 330px;}
    80%{border-radius: 250px 270px 300px 240px;}
    100%{border-radius: 270px 260px 260px 390px;}
}
.icon1{
    animation: move1 5s infinite;
}
.icon2{
    animation: move2 5s infinite;
}
@keyframes move1 {
    0%{transform: translate(0px,10px);}
    50%{transform: translate(0px,-10px);}
    100%{transform: translate(0px,10px);}
}
@keyframes move2 {
    0%{transform: translate(0px,-10px);}
    50%{transform: translate(0px,10px);}
    100%{transform: translate(0px,-10px);}
}
.iconflip{
    animation: flip 15s infinite;
}
@keyframes flip {
    0%{transform: rotate(0deg)}
    50%{transform: rotate(360deg)}
    100%{transform: rotate(0deg)}
}

